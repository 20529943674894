import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import Button from "../../shared/button";
import { Row, Container, Col } from "react-bootstrap";
import ProfileBanner from "./profileBanner";
import Section from "./section";

const ThankYouSection = ({
    image,
    name,
    designation,
    title,
    disc1,
    disc2,
    disc3,
    disc4,
    disc5,
    disc6,
    disc7,
}) => {
    return (
        <Container>
            <Row className='gx-5 mt-5'>
                <Col lg={3}>
                    <ProfileBanner
                        image={image}
                        name={name}
                        designation={designation}
                    />
                </Col>
                <Col lg={9}>
                    <Section
                        title={title}
                        disc1={disc1}
                        disc2={disc2}
                        disc3={disc3}
                        disc4={disc4}
                        disc5={disc5}
                        disc6={disc6}
                        disc7={disc7}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default ThankYouSection;
