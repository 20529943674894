import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import ThankYouSection from "../../components/thank-you-section";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import { normalizedData } from "@utils/functions";

const Blog2 = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title='Blog' pathname='/' />
            <Container>
                <ThankYouSection
                    image='https://reliance-cdn.dev.quanticdynamics.cloud/img/news/Reliance%20Insurance%20congratulate%20the%20newly%20appointed%20Chairman%20of%20IDRA1.jpeg'
                    name='blog2'
                    designation='image'
                    title='Reliance Insurance congratulate the newly appointed Chairman of IDRA'
                    disc1='Dr M Mosharraf Hossain, a member of the Insurance Development and Regulatory Authority (Idra), has been appointed chairman (current charge) of the regulator. Idra Member M Mosharraf Hossain is a chartered accountant. He has also had PHD studies on investment of insurance funds.'
                />
            </Container>
        </Layout>
    );
};
Blog2.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query Blog2Query {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Blog2;
