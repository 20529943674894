import styled, { device } from "@theme/utils";

export const Section = styled.div`
    margin-top: 0px;
`;
export const Title = styled.h3`
    color: #000;
`;
export const DescriptionText = styled.p`
    font-size: 17px;
    color: #000;
    text-align: justify;
    line-height: 130%;
    justify-content: space-around;
`;
