import styled from "@theme/utils";
import AboutBg from "../../../data/images/about/3.png";
export const Banner = styled.div`
    position: relative;
    // padding: 0px 0px;
    // background-image: url(${AboutBg});
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    z-index: 1;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    height: 100%;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: inherit;
        // background-image: -webkit-linear-gradient(
        //     90deg,
        //     #6C4098 0%,
        //     #2647c8 100%
        // );
        // background-image: -ms-linear-gradient(90deg, #6C4098 0%, #2647c8 100%);
        opacity: 0.9;
        z-index: -1;
    }
    .logo {
        display: block;
        margin-bottom: 10px;
    }
`;
export const Name = styled.h3`
    color: #6C4098;
    font-weight: 700;
    margin-bottom: 5px;
`;
export const DescriptionText = styled.p`
    color: #fc7418;
    a {
        color: #fff;
    }
`;
