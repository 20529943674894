import React, { useState } from "react";
import { Section, Title, DescriptionText } from "./style.jsx";
const SectionArea = ({
    title,
    disc1,
    disc2,
    disc3,
    disc4,
    disc5,
    disc6,
    disc7,
}) => {
    const [readMore, setreadMore] = useState(false);
    return (
        <Section>
            <Title>{title}</Title>
            <DescriptionText>{disc1}</DescriptionText>
            {readMore ? (
                <div>
                    <DescriptionText>{disc2}</DescriptionText>
                    <DescriptionText>{disc3}</DescriptionText>
                    <DescriptionText>{disc4}</DescriptionText>
                    <DescriptionText>{disc5}</DescriptionText>
                    <DescriptionText>{disc6}</DescriptionText>
                    <DescriptionText>{disc7}</DescriptionText>
                </div>
            ) : null}
            <button
                onClick={() => setreadMore(!readMore)}
                style={{ borderRadius: "0px 10px 10px 0px" }}
            >
                {readMore ? "Read Less <<" : "Read More >>"}
            </button>
        </Section>
    );
};

export default SectionArea;
